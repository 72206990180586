import $ from 'jquery'
import 'slick-carousel'

export function initBasicSlider(selector = '.js-basic-carousel') {
  $(selector).each((i, el) => {
    const carousel = $(el)
    const inner = carousel.find('> .carousel-inner')
    const prevArrow = carousel.find('.carousel-control.left')
    const nextArrow = carousel.find('.carousel-control.right')

    inner.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      prevArrow,
      nextArrow,
      dots: true
    })

    carousel.addClass('carousel-ready')
  })
}

export function initImageTextSlider(selector = '.js-image-text-carousel') {
  $(selector).each((i, el) => {
    const carousel = $(el)
    const inner = carousel.find('> .carousel-inner')
    const prevArrow = carousel.find('.left-arrow')
    const nextArrow = carousel.find('.right-arrow')
    const appendDots = carousel.find('.dots')

    inner.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      prevArrow,
      nextArrow,
      dots: true,
      fade: true,
      appendDots
    })

    carousel.addClass('carousel-ready')
  })
}

export function initStandardSlider(selector = '.js-carousel') {
  $(selector).each((i, el) => {
    const carousel = $(el)
    const inner = carousel.find('> .carousel-inner')
    const prevArrow = carousel.find('.carousel-control.left')
    const nextArrow = carousel.find('.carousel-control.right')

    inner.slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      prevArrow,
      nextArrow,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })

    carousel.addClass('carousel-ready')
  })
}

export function initQuotesSlider(selector = '.js-quotes-carousel') {
  $(selector).each((i, el) => {
    const carousel = $(el)
    const inner = carousel.find('> .carousel-inner')
    const prevArrow = carousel.find('.carousel-control.left')
    const nextArrow = carousel.find('.carousel-control.right')

    inner.slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: false,
      prevArrow,
      nextArrow,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    })

    carousel.addClass('carousel-ready')
  })
}

export function initProductSlider(
  selector = '.js-product-carousel',
  thumbsSelector = '.js-product-carousel-thumbnails'
) {
  $(selector).each((i, el) => {
    const carousel = $(el)
    const thumbs = carousel.find(thumbsSelector)
    const inner = carousel.find('> .carousel-inner')
    const prevArrow = carousel.find('.carousel-control.left')
    const nextArrow = carousel.find('.carousel-control.right')

    const config = {
      slidesToScroll: 1,
      slidesToShow: 1,
      infinite: false,
      prevArrow,
      nextArrow
    }

    if (thumbs.length) {
      config.asNavFor = thumbs
    }

    const thumbsConfig = {
      slidesToScroll: 4,
      slidesToShow: 4,
      focusOnSelect: true,
      asNavFor: inner,
      prevArrow: null,
      nextArrow: null,
      infinite: false
    }

    inner.slick(config)

    if (thumbs.length) {
      thumbs.slick(thumbsConfig)
    }

    carousel.addClass('carousel-ready')
  })
}
